body {
  font-family: "Montserrat", sans-serif;
  font-family: "Montserrat", sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* FEATURE CLASSES */

.color-blue {
  color: #1c63b8;
}

.text-uppercase {
  text-transform: uppercase;
}

/* PREVENT SELECT */

.prevent-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* SCROLL BAR CSS */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #1c63b8;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  background: transparent;
}

/* TOPBAR CSS */

.topbar-container {
  background-color: #1c63b8;
}
.topbar-a-container > a {
  color: white;
  text-decoration: none;
}

/* NAVBAR COMPONENT CSS */

span {
  font-size: 22px;
}
nav {
  overflow: hidden;
}
.btn-nav {
  border-radius: 30px;
}
.nav-link:hover {
  color: #ffb512;
}
a {
  text-decoration: none;
  color: black;
}
.nav-a:hover {
  color: #1c63b8;
}
.dropdown-menu {
  z-index: 100;
}
.dropdown:hover > .dropdown-menu {
  display: block;
  z-index: 100;
}

.dropdown > .dropdown-toggle:active {
  pointer-events: none;
}
.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #1c63b8;
  transform-origin: bottom right;
  transition: transform 0.35s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* BANNER CSS */

.banner-heading {
  font-size: 72px;
  -webkit-text-stroke: #141d2a;
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
}
.banner-text {
  -webkit-text-stroke: #141d2a;
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
}

.banner-main-div {
  border-bottom-left-radius: 60px;
  background-image: url("./Images/bg-vid.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
@media screen and (max-width: 450px) {
  .banner-main-div {
    height: 120vh;
  }
}

/* WELCOME PAGE CSS */

.welcome-text-color {
  color: #1c63b8;
  text-align: justify;
}
.welcome-text {
  font-weight: 250;
  font-size: 23px;
}
.welcome-learn-more:hover {
  color: white;
}

/* EXPLORE WORK CSS*/

.exploreWorkDiv {
  background-color: #1c63b8;
  border-top-left-radius: 80px;
  border-bottom-right-radius: 80px;
}
.slide {
  background-color: #1c63b8;
}
.slide-client {
  background-color: white;
}
.slider {
  border: none;
}
/* RATINGS CSS */

.rating-heading {
  color: #1c63b8;
}
.rating-p {
  font-size: 21px;
}
.rating-final {
  font-size: 90px;
  color: #1c63b8;
}
.rating-total {
  font-size: 45px;
}
.rating-stars {
  color: #fbbc04;
  font-size: 30px;
}
.rating-stars-testimonial {
  color: #fbbc04;
  font-size: 20px;
}
.progress-stars {
  background-color: #fbbc04;
}
.one-star-div {
  width: 30%;
}
.two-star-div {
  width: 17%;
}
.three-star-div {
  width: 25%;
}
.four-star-div {
  width: 38%;
}
.five-star-div {
  width: 70%;
}

.circular-progress-one {
  --size: 250px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: 20px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * pi * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 140);
  animation: progress-animation 10s linear 0s 1 forwards;
}

.circular-progress-one circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  stroke-linecap: round;
}

.circular-progress-one circle.bg {
  stroke: #ddd;
}

.circular-progress-one circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  transition: stroke-dasharray 0.3s linear 0s;
  stroke: #fbbc04;
}

.circular-progress-two {
  --size: 250px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: 20px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * pi * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 110);
  animation: progress-animation 10s linear 0s 1 forwards;
}

.circular-progress-two circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  stroke-linecap: round;
}

.circular-progress-two circle.bg {
  stroke: #ddd;
}

.circular-progress-two circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  transition: stroke-dasharray 0.3s linear 0s;
  stroke: #fbbc04;
}

.circular-progress-three {
  --size: 250px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: 20px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * pi * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);
  animation: progress-animation 10s linear 0s 1 forwards;
}

.circular-progress-three circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  stroke-linecap: round;
}

.circular-progress-three circle.bg {
  stroke: #ddd;
}

.circular-progress-three circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  transition: stroke-dasharray 0.3s linear 0s;
  stroke: #fbbc04;
}

@property --progress {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

@keyframes progress-animation {
  from {
    --progress: 0;
  }
  to {
    --progress: 100;
  }
}
.rating-circle-text {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 25px;
  font-weight: 400;
}
.card-text {
  text-align: justify;
}

/* FOOTER CSS */

a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
a,
a:hover {
  text-decoration: none !important;
}

.content {
  height: 70vh;
}

.footer-20192 {
  position: relative;
  color: #fff;
  padding: 2rem 0;
  padding-bottom: 5%;
  background-color: #141d2a;
  border-top-right-radius: 70px;
  text-align: justify;
}
.footer-20192 .container {
  position: relative;
  padding-top: 5rem;
}
.cta {
  border-top-left-radius: 35px;
}
.footer-20192 h3 {
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 0;
  line-height: 1.5;
}
.footer-20192 .links li {
  margin-bottom: 10px;
  line-height: 1.5;
  font-size: 18px;
  display: block;
}
.footer-20192 .links li a {
  color: #666873;
}
.footer-20192 .links li a:hover {
  color: #fff;
  transform: scaleX(1);
  transform-origin: bottom left;
}
.footer-20192 .social li {
  display: inline-block;
  position: relative;
  margin-left: 5px;
}
.footer-20192 .social li a {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: #1c63b8;
  color: #fff;
}
.footer-20192 .social li a > span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.footer-20192 .footer-logo {
  color: #fff;
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 700;
}
.footer-20192 .copyright {
  color: #666873;
  font-size: 18px;
}
.footer-20192 .cta {
  -webkit-box-shadow: -20px -20px 0 0 rgba(52, 58, 64, 0.2);
  box-shadow: -20px -20px 0 0 rgba(52, 58, 64, 0.2);
  padding: 20px;
  background-color: #1c63b8;
  top: -150px;
  position: relative;
}
.footer-20192 .cta h2,
.footer-20192 .cta h3 {
  line-height: 1.5;
}
.footer-20192 .cta h3 {
  font-size: 20px;
}
.hover-underline-animation-footer {
  display: inline-block;
  position: relative;
}

.hover-underline-animation-footer::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #1c63b8;
  transform-origin: bottom right;
  transition: transform 0.45s ease-out;
}

.hover-underline-animation-footer:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

#footer-links-header {
  font-size: 20px;
}
#footer-social-instagram:hover {
  background-color: white;
  color: #f403b3;
}
#footer-social-x:hover {
  background-color: white;
  color: #000;
}
#footer-social-linkedin:hover {
  background-color: white;
  color: #007ab5;
}
#footer-social-facebook:hover {
  background-color: white;
  color: #0863f7;
}
#footer-social-share:hover {
  background-color: white;
  color: #0863f7;
}
.footer-enquire-btn {
  border: 2px solid white;
  font-size: 20px;
  color: white;
}
.company-address {
  font-size: 20px;
  color: #666873;
}

/* SHARE MODAL */

.share-on-facebook:hover {
  color: #0863f7;
}
.share-on-x:hover {
  color: #000;
}
.share-on-linkedin:hover {
  color: #007ab5;
}

/* PRODUCT SHOW CASE CSS */

.bodyproduct-showcase-body {
  align-items: center;
  background: #e3e3e3;
  display: flex;
  height: 100vh;
  justify-content: center;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.slider {
  background: white;
  /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125); */
  /* height: 100px; */
  margin: auto;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  /* width: 960px; */
}
.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  /* height: 100px; */
  position: absolute;
  /* width: 200px; */
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(360deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  animation: scroll 20s linear infinite;
  display: flex;
  width: calc(50px);
}
/* .slider .slide {
  height: 100px;
  width: 250px;
} */

/* REVIEW SECTION CSS */
.review-card-body {
  background-color: #1c63b8;
  border-radius: 35px;
}
.review-quote {
  font-size: 50px;
}
.all-reviews-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* OUR CLIENTS CSS */

.clients-heading {
  color: #1c63b8;
}
.clients-p {
  font-size: 21px;
}

.wrapper {
  height: 80%;
  max-height: 470px;
  min-height: 141px;
  width: 200px;
  overflow: hidden;
}
.wrapper::after {
  height: 78%;
  max-height: 468px;
  min-height: 280px;
  content: "";
  position: absolute;
}
.boxes {
  top: -50px;
  position: relative;
}
.box {
  height: 100px;
  width: 200px;
  font-size: 25px;
  line-height: 94px;
  text-align: center;
}

/* PRODUCT PAGE CSS */

.product-page-head {
  font-size: 40px;
  color: #1c63b8;
}

.product-page-head-para {
  font-size: 22px;
}
.card-showcase-product {
  font-size: 27px;
}

.product-about-data {
  font-size: 22px;
}
.product-details-hr {
  color: #1c62b5;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

#marquee__container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Manrope", sans-serif;
}

.marquee__title {
  font-size: 3rem;
  color: #1c63b8;
  margin: 2rem 0;
  text-align: center;
}

.marquee {
  background-color: #fff;
  color: lightgrey;
  width: 100%;
  overflow: hidden;
}

.marquee__content {
  list-style: none;
  height: 100%;
  display: flex;
  animation: scrolling 30s linear infinite;
}

.marquee__content li {
  width: 20vw;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@keyframes scrolling {
  0% {
    transform: translateX(-0vw);
  }
  100% {
    transform: translateX(-160vw);
  }
}

/*HOVER / PAUSED STATE*/
.marquee__content:hover {
  animation-play-state: paused;
}

.marquee__img:hover {
  transition: all 0.4s ease;
  border-radius: 10px;
  box-shadow: 0 0 10px #e0e0e0;
}

/*///////////////////////////////////////////////////////////////////////////*/

/*TABLET VERSION*/
@media screen and (max-width: 1100px) {
  .marquee__title {
    font-size: 2.5rem;
    padding: 0rem;
  }

  .marquee__content li {
    /* width: 20vw; */
  }

  .marquee {
    /* width: 95vw; */
    margin: 1rem 0;
  }

  .marquee__img {
    /* width: 20vw; */
    padding: 10px;
  }

  @keyframes scrolling {
    0% {
      transform: translateX(-0vw);
    }
    100% {
      transform: translateX(-200vw);
    }
  }
}

/*MOBILE VERSION*/
@media screen and (max-width: 500px) {
  .marquee__img {
    width: 50vw;
  }

  .marquee__content {
    animation: scrolling 35s linear infinite;
  }

  .marquee__content li {
    width: 100%;
  }

  .marquee--invisible--mobile {
    display: none !important;
  }

  @keyframes scrolling {
    0% {
      transform: translateX(-0vw);
    }
    100% {
      transform: translateX(-760vw);
    }
  }
}

/* LIST CARD CSS */
.main-list-card {
  /* border-bottom-left-radius: 10%; */
  border-bottom-right-radius: 5%;
  border-bottom-left-radius: 5%;
}

.product-line-name {
  color: #1c62b5;
}
.product-description {
  font-size: 22px;
  text-align: justify;
  margin-top: 10px;
}

.product-whatsapp-btn {
  font-size: 20px;
}
.product-call-btn {
  color: white;
  font-size: 23px;
}
.phone-number-call {
  background-color: #1c63b8;
  color: white;
}

/* NOT FOUND PAGE CSS */

.error-container {
  text-align: center;
  font-size: 106px;
  font-family: "Catamaran", sans-serif;
  font-weight: 800;
  margin: 70px 15px;
}
.error-container > span {
  display: inline-block;
  position: relative;
}
.error-container > span.four {
  width: 136px;
  height: 43px;
  border-radius: 999px;
  background: linear-gradient(
      140deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.07) 43%,
      transparent 44%,
      transparent 100%
    ),
    linear-gradient(
      105deg,
      transparent 0%,
      transparent 40%,
      rgba(0, 0, 0, 0.06) 41%,
      rgba(0, 0, 0, 0.07) 76%,
      transparent 77%,
      transparent 100%
    ),
    linear-gradient(to right, #1c63b8, #d89ca4, #e27b7e);
}
.error-container > span.four:before,
.error-container > span.four:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 999px;
}
.error-container > span.four:before {
  width: 43px;
  height: 156px;
  left: 60px;
  bottom: -43px;
  background: linear-gradient(
      128deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.07) 40%,
      transparent 41%,
      transparent 100%
    ),
    linear-gradient(
      116deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.07) 50%,
      transparent 51%,
      transparent 100%
    ),
    linear-gradient(to top, #99749d, #b895ab, #cc9aa6, #d7969e, #e0787f);
}
.error-container > span.four:after {
  width: 137px;
  height: 43px;
  transform: rotate(-49.5deg);
  left: -18px;
  bottom: 36px;
  background: linear-gradient(
    to right,
    #1c63b8,
    #1c63b8,
    #99749d,
    #b895ab,
    #cc9aa6,
    #d7969e,
    #ed8687,
    #ed8687
  );
}

.error-container > span.zero {
  vertical-align: text-top;
  width: 156px;
  height: 156px;
  border-radius: 999px;
  background: linear-gradient(
      -45deg,
      transparent 0%,
      rgba(0, 0, 0, 0.06) 50%,
      transparent 51%,
      transparent 100%
    ),
    linear-gradient(
      to top right,
      #1c63b8,
      #1c63b8,
      #99749d,
      #b895ab,
      #cc9aa6,
      #d7969e,
      #ed8687,
      #ed8687
    );
  overflow: hidden;
  animation: bgshadow 5s infinite;
}
.error-container > span.zero:before {
  content: "";
  display: block;
  position: absolute;
  transform: rotate(45deg);
  width: 90px;
  height: 90px;
  background-color: transparent;
  left: 0px;
  bottom: 0px;
  background: linear-gradient(
      95deg,
      transparent 0%,
      transparent 8%,
      rgba(0, 0, 0, 0.07) 9%,
      transparent 50%,
      transparent 100%
    ),
    linear-gradient(
      85deg,
      transparent 0%,
      transparent 19%,
      rgba(0, 0, 0, 0.05) 20%,
      rgba(0, 0, 0, 0.07) 91%,
      transparent 92%,
      transparent 100%
    );
}
.error-container > span.zero:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 999px;
  width: 70px;
  height: 70px;
  left: 43px;
  bottom: 43px;
  background: #fdfaf5;
  box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.screen-reader-text {
  position: absolute;
  top: -9999em;
  left: -9999em;
}

@keyframes bgshadow {
  0% {
    box-shadow: inset -160px 160px 0px 5px rgba(0, 0, 0, 0.4);
  }
  45% {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  55% {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: inset 160px -160px 0px 5px rgba(0, 0, 0, 0.4);
  }
}
.zoom-area {
  max-width: 490px;
  margin: 30px auto 30px;
  font-size: 19px;
  text-align: center;
}
.link-container {
  text-align: center;
}
a.more-link {
  text-transform: uppercase;
  font-size: 13px;
  background-color: #1c63b8;
  padding: 10px 15px;
  border-radius: 0;
  color: #fff;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  line-height: 1.5;
  text-decoration: none;
  margin-top: 50px;
  letter-spacing: 1px;
}

/* SPINNER PAGE */
.spinner-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 74px); /* Subtracting navbar height */
}

/* CONTACT PAGE CSS */
.contact-page-head {
  color: #1c63b8;
  font-size: 40px;
}

/* ABOUT US CSS  */

.about-us-head {
  color: #1c63b8;
}
.about-us-p {
  text-align: justify;
  font-size: 18px;
}
.about-us-factsheet-main-div {
  background-color: #1c63b8;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
}
td {
  background-color: #1c63b8;
  color: white;
}
.about-us-factsheet {
  font-size: 25px;
}

/* CORPORATE VIDEO CSS  */

.corporate-video-p {
  font-size: 18px;
}

/* REVIEW SECTION CSS  */
.review-page-head {
  font-size: 40px;
  color: #1c63b8;
}
.review-page-p {
  font-size: 20px;
}
